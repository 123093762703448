import { lastOf } from '../util.ts'
import type { Theme } from '../domain/kpi.ts'
import { themes } from '../domain/kpi.ts'
import type { DateString, IntervalInWeek, ScheduleInterval } from './date.ts'
import type { Question, QuestionTag } from './question.ts'
import {
  type SurveyDescriptor,
  type PulseQuestionTag,
  type SurveyQuestionResult,
  type PulseAnswerValue,
  type QuestionScale,
  type QuestionResult,
  type TranslationString,
  type TargetedQuestion,
  translated,
} from './question.ts'

import type { Score } from './stats.ts'
import type { DemoTeamId } from './team.ts'
import type { SimplePulseComment } from './pulseComment.ts'
import type { NonEmptyArray } from './common.ts'
import type { Language } from '../domain/index.ts'
import { translations } from '../translations.ts'
import type {
  TeamId,
  PulseId,
  QuestionId,
  PulseAnswerId,
  PulseRoundId,
  PulseSingleRoundId,
  PulseCustomGuidanceId,
  PulseThemeId,
  PulseKpiId,
  UserId,
} from './ids.ts'
import type { TzDatabaseName } from '../timezones.ts'

export type PulseRoundNotificationReason = 'reminder' | 'ticked'

type Demo<T extends { teamId: TeamId }> = Omit<T, 'teamId'> & { teamId: DemoTeamId }

export type DemoPulse = Demo<Pulse>

export const unlimitedVisibilitySettings = ['workspace-admins', 'everyone-in-workspace'] as const
export type UnlimitedVisibilitySetting = (typeof unlimitedVisibilitySettings)[number]

export const defaultCommentVisibilitySettings = ['teams-admins', 'teams-members'] as const
export type DefaultCommentVisibility = (typeof defaultCommentVisibilitySettings)[number]

export const allowedCommentVisibilitySettings = [
  'workspace-members',
  'teams-members',
  'admins-only',
  'workspace-admins-only',
] as const
export type AllowedCommentVisibility = (typeof allowedCommentVisibilitySettings)[number]

export type Pulse = {
  pulseId: PulseId
  teamId: TeamId
  pulseDisabled: boolean
  breakWeeks: BreakWeeks
  tz: TzDatabaseName
  pulseRoundInterval: IntervalInWeek
  pulseQuestions: PulseQuestion[]
  settings: PulseSettings
} & (
  | {
      status: 'paused'
      lastPublishedPulseRound: { pulseRoundId: PulseRoundId; end: DateString } | null
      nextScheduledPulseRound: null
      currentPulseRound: null
    }
  | {
      status: 'scheduled'
      lastPublishedPulseRound: { pulseRoundId: PulseRoundId; end: DateString } | null
      nextScheduledPulseRound: { begin: DateString }
      currentPulseRound: null
    }
  | {
      status: 'collecting-answers'
      lastPublishedPulseRound: { pulseRoundId: PulseRoundId; end: DateString } | null
      currentPulseRound: { pulseRoundId: PulseRoundId; begin: DateString; end: DateString }
      nextScheduledPulseRound: { begin: DateString } | null
      currentAnsweredCount: number
      currentPulseParticipantCount: number
    }
)

export type PulseSettings = {
  allowedCommentVisibility: AllowedCommentVisibility
  respondentRedactionLimit: number | 'default'
  unlimitedVisibilityFor: UnlimitedVisibilitySetting
  defaultCommentVisibility: DefaultCommentVisibility
}

export const DEFAULT_PULSE_SETTINGS: PulseSettings = {
  allowedCommentVisibility: 'teams-members',
  respondentRedactionLimit: 'default',
  unlimitedVisibilityFor: 'workspace-admins',
  defaultCommentVisibility: 'teams-admins',
}

export type CreatePulseOptions = {
  questionSchedules: QuestionSchedule[]
  pulseRoundInterval: IntervalInWeek
  breakWeeks: BreakWeeks
  disabled: boolean
}

export type UpdatePulseSettingsPayload = { teamId: TeamId } & PulseSettings

export type ReschedulePulsePayload = {
  teamId: TeamId
  pulseId: PulseId
  questionSchedules: QuestionSchedule[]
  pulseDisabled: boolean
  pulseRoundInterval: IntervalInWeek
  breakWeeks: BreakWeeks
}

export const BREAK_WEEK_CHOICES = [0, 1, 3] as const
export type BreakWeeks = (typeof BREAK_WEEK_CHOICES)[number]

export const isBreakWeeks = (n: number): n is BreakWeeks => n === 0 || n === 1 || n === 3

export type PulseQuestion = {
  questionId: QuestionId
  scale: QuestionScale
  questionTag: PulseQuestionTag | null
  pulseQuestionDisabled: boolean
  manuallyScheduledForNextRound: boolean
  overridingScheduleInterval: ScheduleInterval | null
  lastAsked: DateString | null
  teamIds: NonEmptyArray<TeamId>
}

export type PulseQuestionDescriptor = {
  questionId: QuestionId
  tag: PulseQuestionTag | null
  scale: QuestionScale
}

export type PulseAnswer = {
  pulseAnswerId: PulseAnswerId
  pulseRoundId: PulseRoundId
  questionId: QuestionId
  answeredAt: DateString
  pulseAnswerComment: SimplePulseComment | null
  pulseAnswerValue: PulseAnswerValue
}

export type AnsweredSurveyQuestionAnswerState = {
  type: 'answered'
  questionId: QuestionId
  pulseRoundId: PulseRoundId
  answer: PulseAnswer
  targetedTeamIds: TeamId[]
}

export type SurveyQuestionAnswerState =
  | AnsweredSurveyQuestionAnswerState
  | {
      type: 'unanswered'
      questionId: QuestionId
      pulseRoundId: PulseRoundId
      targetedTeamIds: TeamId[]
    }

export type PulseAnswerPayload = {
  teamId: TeamId
  pulseRoundId: PulseRoundId
  questionId: QuestionId
  pulseAnswerValue: PulseAnswerValue
}

export type PulseSingleRound = (
  | { state: 'scheduled'; pulseRoundId: null }
  | { state: 'started' | 'ended'; pulseRoundId: PulseRoundId }
) &
  PulseSingleRoundParams & {
    pulseSingleRoundId: PulseSingleRoundId
    currentAnsweredCount: number
    currentPulseParticipantCount: number
  }

export type PulseSingleRoundParams = {
  beginning: DateString
  ending: DateString
  tz: TzDatabaseName
  questions: NonEmptyArray<TargetedQuestion>
  name: string | null
}

export type CreatePulseSingleRoundParams = Omit<PulseSingleRoundParams, 'beginning'> & {
  teamId: TeamId
  beginning: 'immediately' | DateString
}

export type UpdatePulseSingleRoundParams = CreatePulseSingleRoundParams & {
  pulseSingleRoundId: PulseSingleRoundId
}

export type QuestionResultsByRound = PulseQuestionDescriptor & {
  results: SurveyQuestionResult[]
}

export type QuestionSchedule = {
  questionId: QuestionId
  questionTag: PulseQuestionTag | null
  lastAsked: DateString | null
  pulseQuestionDisabled: boolean
  manuallyScheduledForNextRound: boolean
  overridingScheduleInterval: ScheduleInterval | null
  teamIds: NonEmptyArray<TeamId>
}

export const kpis = ['kpi_engagement', 'kpi_wellbeing', 'kpi_deib', 'kpi_enps'] as const
export type Kpi = (typeof kpis)[number]

export const isKpi = (s: string): s is Kpi => (kpis as readonly string[]).includes(s)
export const isTheme = (s: string): s is Theme => (themes as readonly string[]).includes(s)

export type PulseResult = {
  score: Score | 'redacted'
  n: number | 'redacted'
}

export type PulseRoundResult = PulseResult & SurveyDescriptor

export type EngagementBenchmarks = {
  kpis: { kpi: PulseKpi; score: Score }[]
  themes: { theme: PulseTheme; score: Score }[]
  questions: { questionId: QuestionId; tag: PulseQuestionTag; score: Score }[]
}

export type PulseResults = {
  teamId: TeamId | DemoTeamId
  questions: QuestionResultsByRound[]
  kpis: { kpi: PulseKpi; results: PulseRoundResult[] }[]
  themes: { theme: PulseTheme; results: PulseRoundResult[] }[]
  activity: 'redacted' | { score: Score; n: number }
}

export type LatestPulseResults = {
  teamId: TeamId | DemoTeamId
  questions: QuestionResult[]
  kpis: { kpi: PulseKpi; result: PulseResult }[]
  themes: { theme: PulseTheme; result: PulseResult }[]
  activity: 'redacted' | { score: Score; n: number }
}

export type PulseAnswerConflictReason =
  | 'round-already-published'
  | 'question-not-in-pulse-round'
  | 'pulse-inactive'

export const pulseResultsToLatestPulseResults = (results: PulseResults): LatestPulseResults => {
  const questions = results.questions.map((t): QuestionResult => {
    const results = lastOf(t.results)
    return {
      questionId: t.questionId,
      tag: t.tag,
      counts: results?.counts ?? 'redacted',
      score: results?.score ?? 'redacted',
      n: results?.n ?? 'redacted',
    }
  })

  return {
    teamId: results.teamId,
    questions,
    kpis: results.kpis.flatMap(({ kpi, results }) => {
      const result = lastOf(results)
      return result ? { kpi, result } : []
    }),
    themes: results.themes.flatMap(({ theme, results }) => {
      const result = lastOf(results)
      return result ? { theme, result } : []
    }),
    activity: results.activity,
  }
}

export type PulseInsight = {
  tag: PulseQuestionTag
  value: number
  type: 'benchmarkDiff' | 'pastMonthDiff' | 'workspaceDiff'
}

export type PulseInsights = {
  up: PulseInsight[]
  down: PulseInsight[]
}

const availablePlaybooksStatic = [
  'motivation',
  'satisfaction_with_feedback',
  'wellbeing',
  'learning',
  'clarity',
  'frameworks',
  'psychological_safety',
  'workload',
  'autonomy',
  'dependability',
] as const

export type AvailablePlaybook = (typeof availablePlaybooksStatic)[number]

export const availablePlaybooks = availablePlaybooksStatic as readonly PulseQuestionTag[]

export type PulseResultsByQuestionWithSubteamsTimescale = {
  questionId: QuestionId
  team: QuestionResultsByRound
  subteams: Record<number, QuestionResultsByRound> | Record<DemoTeamId, QuestionResultsByRound>
}

export type PulseResultsByKpiWithSubteamsTimescale = {
  kpi: PulseKpi
  team: PulseRoundResult[]
  subteams: Record<number, PulseRoundResult[]> | Record<DemoTeamId, PulseRoundResult[]>
}

export type PulseResultsByThemeWithSubteamsTimescale = {
  theme: PulseTheme
  team: PulseRoundResult[]
  subteams: Record<number, PulseRoundResult[]> | Record<DemoTeamId, PulseRoundResult[]>
}

export type PulseResultsByLatestWithSubteams = {
  teamId: TeamId | DemoTeamId
  team: LatestPulseResults
  subteams: Record<number | DemoTeamId, LatestPulseResults>
}

export type MultiWorkspaceLatestPulseResults = {
  combined: LatestPulseResults
  workspaces: LatestPulseResults[]
}

export type TeamUserKpiGroups = {
  kpis: {
    kpi: PulseKpi
    groups: {
      80: number
      60: number
      40: number
      20: number
      0: number
      none: number
    }
  }[]
  themes: {
    theme: PulseTheme
    groups: {
      80: number
      60: number
      40: number
      20: number
      0: number
      none: number
    }
  }[]
}

export type NetworkClusterResult = {
  networkClusterId: number
  userIds: UserId[]
  results: LatestPulseResults
}

export type PulseCustomGuidance = {
  pulseCustomGuidanceId: PulseCustomGuidanceId
  teamId: TeamId
  questionId: QuestionId
  content: TranslationString
  linkUrl: string | null
  answerValues: number[]
}

export type SurveyCustomGuidance = PulseCustomGuidance & {
  helpful: boolean | null
  pulseRoundId: PulseRoundId
}

export type PulseCustomGuidanceWithUserStats = PulseCustomGuidance & {
  userPulseCustomGuidances: Pick<
    SurveyCustomGuidance,
    'pulseRoundId' | 'helpful' | 'pulseCustomGuidanceId'
  >[]
}

export type PulseTheme = {
  pulseThemeId: PulseThemeId
  content: {
    name: TranslationString
  }
  tag: Theme | null
  teamId: TeamId | null
  questionIds: QuestionId[]
}

type PulseMetricVisibility = {
  visibleTeamIds: TeamId[] | 'all'
}

type PulseMetricOverviewPin = {
  pinned: boolean
}

export const DEFAULT_THEME_PINNED = false
export const DEFAULT_KPI_PINNED = true

export type PulseThemeWithVisibility = PulseTheme & PulseMetricVisibility & PulseMetricOverviewPin

export type PulseKpi = {
  pulseKpiId: PulseKpiId
  content: {
    name: TranslationString
  }
  tag: Kpi
  pulseThemeIds: PulseThemeId[]
  pulseQuestionIds: QuestionId[]
}

export type PulseKpiWithVisibility = PulseKpi & PulseMetricVisibility & PulseMetricOverviewPin

export type PulseKeyMetric = PulseKpi | PulseTheme

export type PulseKeyMetricWithVisibility = PulseKpiWithVisibility | PulseThemeWithVisibility

export const measurementTypes = ['kpi', 'theme', 'question'] as const

type MeasurementType = (typeof measurementTypes)[number]

export type Measurement =
  | { type: 'kpi'; id: PulseKpiId }
  | { type: 'theme'; id: PulseThemeId }
  | { type: 'question'; id: QuestionId }

export type SerializedMeasurement = `${MeasurementType}_${number}`

export const serializeMeasurement = (measurement: Measurement): SerializedMeasurement => {
  return `${measurement.type}_${measurement.id}`
}

export const parseMeasurement = (measurement: string): Measurement => {
  const [type, id] = measurement.split('_')
  if (!measurementTypes.includes(type as MeasurementType)) {
    throw Error(`Invalid measurement type: ${type}`)
  }
  const parsedId = parseInt(id)
  if (isNaN(parsedId)) {
    throw Error(`Unable to parse measurement id ${id}`)
  }
  return { type, id: parsedId } as Measurement
}

export type ExtendedMeasurement = Measurement | 'all' | 'activity'
export type SerializedExtendedMeasurement = SerializedMeasurement | 'all' | 'activity'

export const serializeExtendedMeasurement = <T extends string>(
  m: Measurement | T
): SerializedMeasurement | T => (typeof m === 'string' ? m : serializeMeasurement(m))

export const parseExtendedMeasurement = <T extends string>(
  s: SerializedMeasurement | T
): Measurement | T => (s === 'activity' || s === 'all' ? s : parseMeasurement(s))

export const measurementTag = ({
  measurement,
  kpis,
  themes,
  questions,
}: {
  measurement: Measurement
  kpis: PulseKpi[]
  themes: PulseTheme[]
  questions: Question[]
}): Kpi | Theme | QuestionTag | null => {
  if (measurement.type === 'kpi') {
    const kpi = kpis.find((kpi) => kpi.pulseKpiId === measurement.id)
    return kpi ? kpi.tag : null
  } else if (measurement.type === 'theme') {
    const theme = themes.find((theme) => theme.pulseThemeId === measurement.id)
    return theme ? theme.tag : null
  } else {
    const question = questions.find((question) => question.questionId === measurement.id)
    return question ? question.tag : null
  }
}

export const translateMeasurement = ({
  measurement,
  lang,
  kpis,
  themes,
  questions,
}: {
  measurement: Measurement | 'activity'
  lang: Language
  kpis: PulseKpi[]
  themes: PulseTheme[]
  questions: Question[]
}): string => {
  if (measurement === 'activity') {
    return translations[lang].surveyActivity
  } else if (measurement.type === 'kpi') {
    const kpi = kpis.find((kpi) => kpi.pulseKpiId === measurement.id)
    return kpi ? translated(kpi.content.name, lang) : ''
  } else if (measurement.type === 'theme') {
    const theme = themes.find((theme) => theme.pulseThemeId === measurement.id)
    return theme ? translated(theme.content.name, lang) : ''
  } else {
    const question = questions.find((question) => question.questionId === measurement.id)
    return question ? translated(question.content.name, lang) : ''
  }
}

export type SurveyContext =
  | { type: 'all' }
  | { type: 'pulse'; pulseId: PulseId }
  | { type: 'survey'; surveyId: PulseRoundId }

export type SerializedSurveyContext = 'all' | `pulse_${PulseId}` | `survey_${PulseRoundId}`

export const serializeSurveyContext = (surveyContext: SurveyContext): SerializedSurveyContext => {
  switch (surveyContext.type) {
    case 'all':
      return 'all'
    case 'pulse':
      return `pulse_${surveyContext.pulseId}`
    case 'survey':
      return `survey_${surveyContext.surveyId}`
    default:
      throw Error('Unknown survey context type')
  }
}

export const parseSurveyContext = (
  serializedSurveyContext: SerializedSurveyContext
): SurveyContext => {
  const [type, id] = serializedSurveyContext.split('_')
  const parsedId = id ? parseInt(id) : null
  if (parsedId !== null && isNaN(parsedId)) {
    throw Error(`Unable to parse survey context id: ${serializedSurveyContext}`)
  }

  switch (type) {
    case 'pulse':
      if (!parsedId) {
        throw Error(`Pulse context id is required: ${serializedSurveyContext}`)
      }
      return { type: 'pulse', pulseId: parsedId as PulseId }
    case 'survey':
      if (!parsedId) {
        throw Error(`Survey context id is required: ${serializedSurveyContext}`)
      }
      return { type: 'survey', surveyId: parsedId as PulseRoundId }
    case 'all':
      return { type: 'all' }
    default:
      throw Error(`Unknown survey context type: ${serializedSurveyContext}`)
  }
}

type SmartSnapshotSummaryV1 = { version: 1; output: string }

export type SmartSnapshotSummaryV2 = {
  version: 2
  oneOnOnePoints: string | null
  teamPoints: string | null
  scores: {
    questionId: QuestionId
    now: Score | 'redacted'
    last: Score | 'redacted'
    diff: { absolute: Score; percentage: Score }
    benchmark: Score | undefined
  }[]
}

export type SmartSnapshotSummary = SmartSnapshotSummaryV1 | SmartSnapshotSummaryV2
